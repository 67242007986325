export const getFullPatti = (open, close) => {
    const openNumber = parseInt(open, 10);
    const closeNumber = parseInt(close, 10);

    const openDigits = openNumber.toString().split('').map(Number);
    const closeDigits = closeNumber.toString().split('').map(Number);

    const openSum = openDigits.reduce((a, b) => a + b, 0);
    const closeSum = closeDigits.reduce((a, b) => a + b, 0);

    const openLastDigit = openSum.toString().slice(-1);
    const closeLastDigit = closeSum.toString().slice(-1);

    if (!close) {
        return `${open} - ${openLastDigit}x - xxx`;
    } else if (open && close) {
        return `${open} - ${openLastDigit}${closeLastDigit} - ${close}`;
    } else {
        return '';
    }
}

export const twentyFourHourToTwelveHourCovert = (string) => {
    const [hours, minutes] = string.split(":");
    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    const period = formattedHours >= 12 ? 'PM' : 'AM';
    const displayHours = formattedHours > 12 ? formattedHours - 12 : formattedHours;
    return `${displayHours}:${formattedMinutes} ${period}`;
}


export const getMiddlePattiColorClass = (middlePatti) => {
    // Define the numbers to check for
    const numbersToCheck = ["16", "27", "38", "49", "50", "61", "72", "83", "94", "05", "11", "22", "33", "44", "55", "66", "77", "88", "99", "00"];

    // Check if the middle patti contains any of the specified numbers
    const containsMatchingNumber = numbersToCheck.some(number => middlePatti.includes(number));

    // Return the appropriate class name
    return containsMatchingNumber ? "text-red-600" : "";
};


export const getMiddlePatti = (open, close) => {
    const openNumber = parseInt(open, 10);
    const closeNumber = parseInt(close, 10);

    const openDigits = isNaN(openNumber) ? [] : openNumber.toString().split('').map(Number);
    const closeDigits = isNaN(closeNumber) ? [] : closeNumber.toString().split('').map(Number);

    const openSum = openDigits.reduce((a, b) => a + b, 0);
    const closeSum = closeDigits.reduce((a, b) => a + b, 0);

    const openLastDigit = openDigits.length > 0 ? openSum.toString().slice(-1) : '*';
    const closeLastDigit = closeDigits.length > 0 ? closeSum.toString().slice(-1) : '*';

    if (openDigits.length > 0 && closeDigits.length > 0) {
        return `${openLastDigit}${closeLastDigit}`;
    } else if (openDigits.length > 0) {
        return `${openLastDigit}*`;
    } else if (closeDigits.length > 0) {
        return `*${closeLastDigit}`;
    } else {
        return '**';
    }
};

export const getNewFullPatti = (open, close) => {
    const openNumber = parseInt(open, 10);
    const closeNumber = parseInt(close, 10);

    const openDigits = openNumber.toString().split('').map(Number);
    const closeDigits = closeNumber.toString().split('').map(Number);

    const openSum = openDigits.reduce((a, b) => a + b, 0);
    const closeSum = closeDigits.reduce((a, b) => a + b, 0);

    const openLastDigit = openSum.toString().slice(-1);
    const closeLastDigit = closeSum.toString().slice(-1);

    if (!close) {
        return {
            firstValue: open,
            secondValue: `${openLastDigit}x`,
            thirdValue: "xxx"
        };
    } else if (open && close) {
        return {
            firstValue: open,
            secondValue: openLastDigit + closeLastDigit,
            thirdValue: closeNumber
        };
    } else {
        return {
            firstValue: '',
            secondValue: '',
            thirdValue: ''
        };
    }
}

