import React, {useEffect, useState} from "react";
import {collection, getDocs,  query, where,} from "firebase/firestore";
import {db} from "../Firebase/firebase";
import {useParams} from "react-router-dom";
import {zoomLevel} from "zoom-level";
import {getMiddlePatti, getMiddlePattiColorClass} from "../functions/functions";
import "./mainPage.css";


export default function FullPattiChart() {
    const [draws, setDraws] = useState([]);
    const [drawHeading, setDrawHeading] = useState([])

    const {drawName, jodi, pattern} = useParams()
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")

    const groupDrawsByWeek = (drawsData) => {
        const groupedDraws = {};

        drawsData.forEach((draw) => {
            const weekStart = new Date(draw.sortDate);
            // Set to the first day of the week (Monday)
            weekStart.setDate(weekStart.getDate() - (weekStart.getDay() - 1 + 7) % 7);

            const weekStartString = weekStart.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
            });

            if (!groupedDraws[weekStartString]) {
                groupedDraws[weekStartString] = [];
            }

            groupedDraws[weekStartString].push(draw);
        });

        Object.keys(groupedDraws).map((key) => {
            // Loop through the draws to check if order of day is maintained or add a dummy
            const newDraws = [];
            if (customDay(groupedDraws[key][0].sortDate) > 0) {
                for (let i = 0; i < customDay(groupedDraws[key][0].sortDate); i++) {
                    newDraws.push({
                        isEmpty: true,
                        sortDate: new Date()
                    });
                }
            }

            groupedDraws[key].forEach((draw, index) => {

                let current = customDay(draw.sortDate);
                let next = index < groupedDraws[key].length-1 ? customDay(groupedDraws[key][index+1].sortDate) : null;
                if ((((current+1) % 7)) === next || next < current)
                    newDraws.push(draw);
                else {
                    newDraws.push(draw);
                    for (let i = current; i < next-1; i++) {
                        newDraws.push({
                            isEmpty: true,
                            sortDate: new Date()
                        });
                    }

                }
            })
            groupedDraws[key] = newDraws
        })

        return groupedDraws;
    };

    const customDay = (date) => {
        const dayIndex = date.getDay(); // This returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday
        return dayIndex === 0 ? 6 : dayIndex - 1; // Adjust Sunday to be at index 6 instead of 0
    };

    const searchMumbaiPattern = async () => {
        try {
            const allDrawsRef = collection(db, "all-draws-beta");
            const queryRef = query(allDrawsRef, where("idDrawName", "==", drawName));
            const querySnapshot = await getDocs(queryRef);

            const drawsData = querySnapshot.docs.map((doc) => doc.data());
            const mappedDrawsData = drawsData.map((draw) => {
                const dateParts = draw.date.split("/");
                const day = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10) - 1;
                const year = parseInt(dateParts[2], 10);
                const dateObject = new Date(year, month, day);
                return { ...draw, sortDate: dateObject };
            });

            const sortedDraws = mappedDrawsData.sort((a, b) =>
                a.sortDate - b.sortDate
            );

            // Remove duplicates based on date and openRoundPatti
            const uniqueDraws = [];
            const seenCombinations = new Set();

            sortedDraws.forEach((draw) => {
                if (draw.openRoundPatti) {
                    const combination = `${draw.date}-${draw.openRoundPatti}`;

                    if (!seenCombinations.has(combination)) {
                        uniqueDraws.push(draw);
                        seenCombinations.add(combination);
                    }
                } else if (draw.number) {
                    const combination = `${draw.date}-${draw.number}`;

                    if (!seenCombinations.has(combination)) {
                        uniqueDraws.push(draw);
                        seenCombinations.add(combination);
                    }
                }
            });


            uniqueDraws.every((draw) => {
                if (draw.drawName) {
                    setDrawHeading(draw.drawName)
                    setStartTime(draw.startTime)
                    setEndTime(draw.closingTime)
                    return false
                }
                return true
            })

            const filteredDraws = uniqueDraws.filter((draw) => {
                if (drawName === "rajdhani-night") {
                    const dayOfWeek = draw.sortDate.getDay();
                    // getDay() returns 0 for Sunday and 6 for Saturday
                    if (dayOfWeek === 6 || dayOfWeek === 0) {
                        console.log(`Filtered out draw on: ${draw.date} (Day of Week: ${dayOfWeek})`);
                        return false;
                    }
                }
                return true;
            });

            // Set draw heading, start time, and end time
            filteredDraws.every((draw) => {
                if (draw.drawName) {
                    setDrawHeading(draw.drawName);
                    setStartTime(draw.startTime);
                    setEndTime(draw.closingTime);
                    return false; // exit loop after setting values
                }
                return true;
            });

            setDraws(filteredDraws);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        searchMumbaiPattern();
    }, []);



    useEffect(() => {
        searchMumbaiPattern();
    }, []);


    const groupedDraws = groupDrawsByWeek(draws);

    function getEndOfWeek(startDateString) {
        // Convert the input string to a Date object
        const [day, month, year] = startDateString.split('/').map(Number);
        const start = new Date(year, month - 1, day);

        // Check if the conversion is successful
        if (isNaN(start.getTime())) {
            return "Invalid date"; // Return an error message for invalid dates
        }

        const end = new Date(start);

        // Calculate the end of the week (Sunday)
        end.setDate(start.getDate() + (6 - start.getDay()));

        // Format the end date as DD/MM/YYYY
        const endString = `${end.getDate()}/${end.getMonth() + 1}/${end.getFullYear()}`;

        return endString;
    }


    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [groupedDraws]);

    zoomLevel()

    let fiveDaysReturn = {
        mainDiv: "grid grid-cols-6",
        innerDiv: "col-span-6 grid grid-cols-6",
        daysArray: ["Date", "Mon", "Tue", "Wed", "Thu", "Fri"]
    };

    let sixDaysReturn = {
        mainDiv: "grid grid-cols-7",
        innerDiv: "col-span-7 grid grid-cols-7",
        daysArray: ["Date", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    };

    let sevenDaysReturn = {
        mainDiv: "grid grid-cols-8",
        innerDiv: "col-span-8 grid grid-cols-8",
        daysArray: ["Date", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
    };

    const numberOfWeeksToShowAndDaysToShow = () => {
        const sixDaysReturnDraws = [
            "good-morning", "bombay-exp", "golden", "kalyan", "goldstar", "golden-night",
            "time-bazar", "tara-mumbai-day", "milan-day", "rajdhani-day", "milan-night",
            "night-time-bazar", "madhur-night"
        ];

        const sevenDaysReturnDraws = [
            "sridevi", "madhur-morning", "madhur-day", "sridevi-night", "goa-golden"
        ];

        const fiveDaysReturnDraws = [
            "rajdhani-night", "tara-mumbai-night", "kalyan-night", "main-bazar"
        ];

        if (sixDaysReturnDraws.includes(drawName)) {
            return sixDaysReturn;
        } else if (sevenDaysReturnDraws.includes(drawName)) {
            return sevenDaysReturn;
        } else if (fiveDaysReturnDraws.includes(drawName)) {
            return fiveDaysReturn;
        } else {
            return null; // Handle the case where drawName does not match any case
        }
    };


    const hideColumnsForDraw = (drawName, drawIndex) => {
        const hideCasesByIndex = {
            7: ["good-morning", "bombay-exp", "golden", "kalyan", "goldstar", "golden-night",
                "time-bazar", "tara-mumbai-day", "milan-day", "rajdhani-day", "goa-golden",
                "night-time-bazar"],
            8: ["sridevi", "madhur-morning", "madhur-day", "sridevi-night"],
            6: ["tara-mumbai-night", "milan-night", "madhur-night"],
            5: ["rajdhani-night", "main-bazar", "kalyan-night"]
        };

        return hideCasesByIndex[drawIndex] && hideCasesByIndex[drawIndex].includes(drawName)
            ? 'hidden'
            : '';
    };



    return (
        <div className={`mx-auto max-w-[100vw] min-w-[450px] lg:min-w-[1300px] bg-[#092019] `}>
            <div
                className="col-span-8 flex justify-between text-xs sm:text-lg lg:text-3xl text-white items-center sticky top-0 bg-[#092019]  font-bold text-center p-4">
                <div className={"flex gap-3 uppercase font-extralight"} style={{letterSpacing: "6px"}}>
                    <div className={"text-yellow-400"}>Open</div>
                    {startTime}
                </div>
                <hr className="my-10 border-t-1 border-[#135C45] w-1/4"/>
                <h1 className={"uppercase text-yellow-300 text-sm"} style={{letterSpacing: "6px"}}>{drawHeading}</h1>
                <hr className="my-10 border-t-1 border-[#135C45] w-1/4"/>
                <div className={"flex gap-3 uppercase font-extralight"} style={{letterSpacing: "6px"}}>
                    <div className={"text-yellow-400"}>Close</div>
                    {endTime}
                </div>
            </div>

            <div className={`${numberOfWeeksToShowAndDaysToShow().mainDiv}`}>
                <div className={`${numberOfWeeksToShowAndDaysToShow().innerDiv} sticky top-24 py-4 bg-[#092019]`}>
                    {(numberOfWeeksToShowAndDaysToShow().daysArray).map((day, index) => (
                        <div
                            key={day}
                            className={`font-extralight text-xs sm:text-sm text-white lg:text-3xl flex justify-center`}
                        >
                            {day}
                        </div>
                    ))}
                </div>

                {Object.keys(groupedDraws).map((weekStartString, weekIndex) => (
                    <div key={weekStartString} className={`border ${numberOfWeeksToShowAndDaysToShow().innerDiv} border-[#135C45]`}>
                        <div
                            className="col-span-1 text-[9px] font-bold sm:pl-6 text-white/90 md:text-base lg:text-2xl sm:py-6 flex flex-col items-center">
                            <div>{weekStartString}</div>
                            <div>
                                <svg className="w-4" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"
                                     viewBox="0 0 256 256">
                                    <path
                                        d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"></path>
                                </svg>
                            </div>
                            <div>{getEndOfWeek(weekStartString)}</div>
                        </div>
                        {groupedDraws[weekStartString].map((draw, drawIndex) => (
                            <div
                                key={draw.sortDate.toLocaleDateString()}
                                className={`flex border-l text-white/80 font-extralight border-[#135C45] items-center sm:py-5 justify-center }`}
                            >
                                <div
                                    className={`flex justify-evenly ${hideColumnsForDraw(drawIndex, drawIndex)}`}
                                >
                                    {(jodi === "false" && pattern === "m") && (
                                        <div className="flex flex-col text-xs font-bold sm:text-3xl">
                                            {draw.openRoundPatti
                                                ? draw.openRoundPatti.split('').map((digit, index) => (
                                                    <span key={index} className="">
                                            {digit}
                                        </span>
                                                ))
                                                : Array.from({length: 3}, (_, index) => (
                                                    <span key={index} className="">*</span>
                                                ))}
                                        </div>
                                    )}
                                    {(pattern === "m") && (
                                        <div
                                            className={`md:mx-1 p-1 text-lg font-bold lg:text-6xl md:text-2xl  flex flex-col justify-center lg:p-3 ${getMiddlePattiColorClass(getMiddlePatti(draw.openRoundPatti, draw.closeRoundPatti))}`}
                                        >
                                            {getMiddlePatti(draw.openRoundPatti, draw.closeRoundPatti)}
                                        </div>
                                    )}
                                    {(pattern === "d") && (
                                        <div
                                            className="lg:mx-4 text-xs lg:text-5xl font-bold flex flex-col justify-center lg:p-3">
                                            {draw.number}
                                        </div>
                                    )}
                                    {(jodi === "false" && pattern === "m") && (
                                        <div className="flex flex-col text-xs font-bold sm:text-3xl">
                                            {draw.closeRoundPatti
                                                ? draw.closeRoundPatti.split('').map((digit, index) => (
                                                    <span key={index} className="mr-1">
                                            {digit}
                                        </span>
                                                ))
                                                : Array.from({length: 3}, (_, index) => (
                                                    <span key={index} className="mr-1">*</span>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

        </div>
    );
}

