import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger
import { Link } from "react-router-dom";
import { getNewFullPatti, twentyFourHourToTwelveHourCovert } from "../../functions/functions";

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

const MumbaiTodayDraws = ({ mumbaiPatternDraws, todaysDraws }) => {

    const containerRef = useRef(null);

    useEffect(() => {
        // Ensure container is available before animating
        //
        // if (!containerRef.current || !containerRef.current.children || containerRef.current.children.length === 0) return;
        //
        // let children = new Array(containerRef.current.children)
        // console.log(children)
        //
        // gsap.set([...children], {autoAlpha: 0})
        //
        // children.forEach(child => {
        //     // Use ScrollTrigger to trigger the animation
        //     ScrollTrigger.create({
        //         trigger: child,
        //         start: "top 80%", // adjust as needed
        //         onEnter: () => {
        //             gsap.to(child, {
        //                 autoAlpha: 1,
        //                 duration: 200,
        //                 stagger
        //             })
        //         } ,
        //         scrub: true // smooth animation when scrolling
        //     });
        // })

    }, [mumbaiPatternDraws]);

    return (
        <div className={"bg-[#173E32] w-[85%]  border-[#1B8E6A] rounded-3xl custom-container"}>
            <div className="todays-result w-full" ref={containerRef}>
                {mumbaiPatternDraws.length > 0 && mumbaiPatternDraws.map(draw => (
                    <div key={draw.id}>
                        <div className="px-10 pt-10">
                            <div className={"flex flex-col sm:flex-row items-center justify-between"}>
                                <div className={"flex-col"}>
                                    <h3 className="text-2xl sm:text-3xl text-white font-bold mb-3"
                                        style={{letterSpacing: "2px"}}>{draw.drawName}</h3>
                                    <div className="text-2xl rounded-2xl sm-px-4 font-extrabold">
                                        {todaysDraws.length > 0 && todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                            <div>
                                                {(() => {
                                                    const fullPatti = (getNewFullPatti(todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).openRoundPatti, todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).closeRoundPatti))

                                                    if (fullPatti) {
                                                        return (
                                                            <div className="flex space-x-1">
                                                                <div
                                                                    className="bg-[#135C45] text-white rounded-lg p-1 px-5">
                                                                    {fullPatti.firstValue}
                                                                </div>
                                                                <div
                                                                    className="bg-[#135C45] text-white rounded-lg p-1 relative px-5">
                                                                    <div
                                                                        className={"flex left-0 -translate-x-1/2 -translate-y-1/2 top-1/2 items-center justify-center rounded-lg absolute bg-[#173E32] w-6 h-6"}
                                                                        style={{transform: "translate(calc(-50% - 3px), -50%)"}}
                                                                    >
                                                                        <div className={"bg-white w-3 h-[3px]"}/>
                                                                    </div>
                                                                    {fullPatti.secondValue}
                                                                </div>
                                                                <div
                                                                    className="bg-[#135C45] text-white rounded-lg p-1 relative px-5">
                                                                    <div
                                                                        className={"flex left-0 -translate-x-1/2 -translate-y-1/2 top-1/2 items-center justify-center rounded-lg absolute bg-[#173E32] w-6 h-6"}
                                                                        style={{transform: "translate(calc(-50% - 3px), -50%)"}}
                                                                    >
                                                                        <div className={"bg-white w-3 h-[3px]"}/>
                                                                    </div>
                                                                    {fullPatti.thirdValue}
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return <span>xxx-xx-xxx</span>;
                                                    }
                                                })()}
                                            </div>
                                        ) : (
                                            <div>
                                                xxx-xx-xxx
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex items-center mt-4">
                                        <div className="flex-shrink-0 text-xl sm:text-2xl text-white/70 font-light">
                                            {twentyFourHourToTwelveHourCovert(draw.startTime)}
                                        </div>
                                        <hr className="flex-shrink-0 w-20 mx-2 flex-grow my-1 border-t-1 border-zinc-400"/>
                                        <div className="flex-shrink-0 text-xl sm:text-2xl text-white/70 font-light">
                                            {twentyFourHourToTwelveHourCovert(draw.closingTime)}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center mt-10">
                                    <div className="border-2 border-yellow-400 rounded-full inline-block">
                                        <Link to={`/chart/${draw.id}/false/m`}>
                                            <div className="text-lg font-bold p-1" style={{color: '#092019'}}>
                                                <div className={"flex items-center"}>
                                                    <svg width="30" height="30" viewBox="0 0 11 11" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg" className={"pl-1"}>
                                                        <path
                                                            d="M8.69957 1.99182H2.78465C2.61035 1.99182 2.44319 2.06106 2.31993 2.18431C2.19668 2.30757 2.12744 2.47473 2.12744 2.64903V8.56395C2.12744 8.73826 2.19668 8.90542 2.31993 9.02867C2.44319 9.15192 2.61035 9.22116 2.78465 9.22116H8.69957C8.87388 9.22116 9.04104 9.15192 9.16429 9.02867C9.28754 8.90542 9.35678 8.73826 9.35678 8.56395V2.64903C9.35678 2.47473 9.28754 2.30757 9.16429 2.18431C9.04104 2.06106 8.87388 1.99182 8.69957 1.99182ZM8.69957 5.27789H6.07072V2.64903H8.69957V5.27789ZM5.41351 2.64903V5.27789H2.78465V2.64903H5.41351ZM2.78465 5.9351H5.41351V8.56395H2.78465V5.9351ZM8.69957 8.56395H6.07072V5.9351H8.69957V8.56395Z"
                                                            fill="#FFF080"/>
                                                    </svg>
                                                    <div className={"text-yellow-400 font-light pr-3"}>
                                                        Chart
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"mt-10"}>
                                <hr className="w-full my-1 border-t-2 border-[#1B8E6A] items-center"/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default MumbaiTodayDraws;
